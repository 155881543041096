import React from 'react';

interface VimeoVideoPlayerProps {
  url: string;
}

const VimeoVideoPlayer: React.FC<VimeoVideoPlayerProps> = ({ url }) => {
  // Function to extract the video ID from the Vimeo URL
  const extractVideoId = (url: string) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const pathSegments = pathname.split('/').filter(Boolean);
      const videoId = pathSegments.pop();
      return videoId;
    } catch (error) {
      const regex = /vimeo\.com\/(?:.*\/)?(\d+)/;
      const match = url.match(regex);
      if (match && match[1]) {
        return match[1];
      }
      return null;
    }
  };

  const videoId = extractVideoId(url);

  if (!videoId) {
    return <div>Error: Invalid Vimeo URL</div>;
  }

  const iframeSrc = `https://player.vimeo.com/video/${videoId}?background=1&autoplay=1&muted=1&loop=1&dnt=1`;

  return (
    <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
      <iframe
        src={iframeSrc}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        className="absolute top-0 left-0 w-full h-full"
      />
    </div>
  );
};

export default React.memo(VimeoVideoPlayer);
