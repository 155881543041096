import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useTranslation } from "react-i18next";
import HeroHeading from "./HeroHeading";
import HeroDescription from "./HeroDescription";
import UnifiedPromptInput from "../UnifiedPromptInput";
// import TrustedBy from "../TrustedBy";

interface HeroContentProps {
  setShowEmailModal: (show: boolean) => void;
}

function HeroContent({ setShowEmailModal }: HeroContentProps) {
  const { theme } = useTheme();

  return (
    <div
      className={`elem101 relative z-10 opacity-100 box-border w-[100%] max-w-[1268px] z-[1] relative mx-auto mobile:px-[20px] before:content-['_'] before:table after:content-['_'] after:table after:clear-both tablet:px-[24px] laptop:px-[24px] desktop:px-[24px] ${
        theme === "light" ? "text-black" : "text-white"
      }`}
      data-w-id="fa3d264c-0fef-9855-1fa8-f34b79da40d4"
      original-class="container-default width-100 z-index-1 w-container"
    >
      <div
        className="elem102 box-border flex flex-col items-center justify-center mobile:gap-y-[40px] mobile:mb-[64px] mobile:mt-[90px] tablet:gap-y-[56px] tablet:mb-[100px] tablet:mt-[110px] laptop:gap-y-[28px] desktop:gap-y-[28px] laptop:mb-[164px] laptop:mt-[80px] desktop:mb-[164px] desktop:mt-[80px]"
        original-class="hero-section---home-page"
      >
        <div
          className="elem103 opacity-100 box-border text-center max-w-[800px]"
          data-w-id="fa3d264c-0fef-9855-1fa8-f34b79da40d5"
        >
          <HeroHeading />
          <HeroDescription />
          <UnifiedPromptInput setShowEmailModal={setShowEmailModal} />
        </div>
      </div>
      {/* <TrustedBy /> */}
    </div>
  );
}

export default HeroContent;
