export const steps = [
  {
    title: "FourSteps.step1.title",
    description: "FourSteps.step1.description",
    media: {
      type: "iframe" as const,
      // src: "https://vz-9b5abe92-eb3.b-cdn.net/df7e6e37-a8e0-4bdc-a280-40a5d87a0625/playlist.m3u8",
      src: "https://vimeo.com/1016422063?share=copy#t=0"
    },
  },
  {
    title: "FourSteps.step2.title",
    description: "FourSteps.step2.description",
    media: {
      type: "iframe" as const,
      // src: "https://vz-9b5abe92-eb3.b-cdn.net/b646a098-f6be-4f41-b74a-817e3a190176/playlist.m3u8",
      src: "https://vimeo.com/1017128362?share=copy#t=0"
    },
  },
  {
    title: "FourSteps.step3.title",
    description: "FourSteps.step3.description",
    media: {
      type: "iframe" as const,
      // src: "https://vz-9b5abe92-eb3.b-cdn.net/2dd60a82-83f0-4dc2-9c0e-1d47284ff3fc/playlist.m3u8",
      src: "https://vimeo.com/1017128025?share=copy#t=0"
    },
  },
  {
    title: "FourSteps.step4.title",
    description: "FourSteps.step4.description",
    media: {
      type: "iframe" as const,
      // src: "https://vz-9b5abe92-eb3.b-cdn.net/7ff02382-5c88-4698-95bd-f5c282feaae1/playlist.m3u8",
      src: "https://vimeo.com/1017127517?share=copy#t=0"
    },
  },
];
